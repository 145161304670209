import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/home/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '广州网格智能科技有限公司'
    }
  },
  {
    path: '/ir-camera',
    name: 'IRCamera',
    component: () => import('@/views/detail-page/ir-camera/index.vue'),
    meta: {
      title: '超高清型红外监测仪————红外相机'
    }
  },
  {
    path: '/ir-camera2',
    name: 'IRCamera2',
    component: () => import('@/views/detail-page/ir-camera2/index.vue'),
    meta: {
      title: '超高清型红外监测仪————红外相机'
    }
  },
  {
    path: '/linweishi',
    name: 'Linweishi',
    component: () => import('@/views/detail-page/linweishi/index.vue'),
    meta: {
      title: '智慧气象站————天圻'
    }
  },
  {
    path: '/command-center',
    name: 'commandCenter',
    component: () => import('@/views/detail-page/command-center/index.vue'),
    meta: {
      title: '智慧大屏'
    }
  },
  {
    path: '/pancam',
    name: 'pancam',
    component: () => import('@/views/detail-page/pancam/index.vue'),
    meta: {
      title: '360°全景相机'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
