<template>
  <div class="home">
    <div class="home_container">
      <!-- 背景 -->
      <img class="home_bg" src="../image/bg.jpg" alt="背景" />
      <!-- 全景相机 -->
      <!-- <img class="home_fullview" src="../image/fullview.png" alt="全景" /> -->
      <!-- 狗狗 -->
      <!-- <img class="home_camera" src="../image/camera.png" alt="相机" /> -->
    </div>
    <!-- 无人机 -->
    <img class="home_uav" src="../image/uav.png" alt="无人机" />
    <!-- 内容 -->
    <div class="home_content">
      <h2>
        <span>一网一世界</span>
        <span>一格一智能</span>
      </h2>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #192124;
  margin-top: 80px;
  &_container {
    position: absolute;
    width: 1920px;
    height: 100%;
  }

  &_bg {
    position: absolute;
    width: 100%;
    bottom: 20px;
    object-fit: cover;
  }

  &_uav {
    position: absolute;
    top: -130px;
    left: 24px;
    width: 100px;
    height: 100px;
    offset-path: path(
      "M215.5,374.5c0,0,110,322,213,363s266-72,266-72s84-66,216-66s228,95,228,95s130,73,309-109s259-123,259-123s103,67,0,78s-112-166-112-166s0-128-193-112s-591-18-591-18S128.5,169.5,215.5,374.5z"
    );
    // animation: fly 15s infinite;
    // animation-timing-function: ease;
    animation: move 15s infinite;
    animation-timing-function: linear;
  }

  &_fullview {
    position: absolute;
    right: 337px;
    top: 260px;
    width: 100px;
  }
  &_camera {
    position: absolute;
    left: 330px;
    bottom: 12%;
    width: 300px;
  }
  &_content {
    position: absolute;
    top: 110px;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, 0);
    max-width: 960px;
    color: #fff;
  }

  &_content h2 {
    font-size: 68px;
    font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
    font-weight: 800;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;

    span:last-child {
      margin-left: 36px;
    }
  }

  &_content p {
    font-size: 18px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    text-indent: 2em;
    margin-top: 28px;
  }
}
@keyframes move {
  0% {
    offset-distance: 0%;
  }
  13% {
    offset-distance: 12%;
    transform: rotateX(58deg) rotateZ(328deg) scale(0.8);
  }
  26% {
    offset-distance: 12%;
    transform: rotateX(58deg) rotateZ(0deg) scale(0.8);
  }
  45% {
    offset-distance: 60%;
    transform: rotateX(58deg) rotateZ(0deg) scale(0.8);
  }
  47% {
    offset-distance: 60%;
    transform: rotate(324deg) rotateX(55deg) rotateZ(323deg) scale(0.8)
  }
  49% {
    offset-distance: 60%;
    transform: rotate(324deg) rotateX(70deg) rotateZ(323deg) scale(0.8)
  }
  52% {
    offset-distance: 60%;
    transform: rotate(324deg) rotateX(55deg) rotateZ(323deg) scale(0.8)
  }
  66% {
    offset-distance: 60%;
    transform: rotate(324deg) rotateX(70deg) rotateZ(323deg) scale(0.8)
  }
  78% {
    offset-distance: 80%;
    transform: rotateX(61deg) rotateZ(86deg) scale(0.8);
  }
  86% {
    offset-distance: 100%;
    transform: rotateX(71deg) rotateZ(86deg) scale(0.8);
  }
  100% {
    offset-distance: 100%;
  }
}
@keyframes fly {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotateZ(0) scale(1);
  }
  10% {
    opacity: 0.8;
    transform: translate3d(0, 150px, 0) rotateZ(32deg) scale(0.8);
  }
  30% {
    opacity: 0.8;
    transform: translate3d(168px, 301px, 0) rotateX(58deg) rotateZ(328deg)
      scale(0.8);
  }
  40% {
    opacity: 0.8;
    transform: translate3d(168px, 301px, 0) rotateX(58deg) rotateZ(0deg)
      scale(0.8);
  }
  // 50% {
  //   opacity: 0.8;
  //   transform: translate3d(368px, 250px, 0) rotateX(74deg) rotateZ(0deg) scale(0.8);
  // }
  50% {
    opacity: 0.8;
    transform: translate3d(568px, 200px, 0) rotateX(66deg) rotateZ(34deg)
      scale(0.8);
  }
  60% {
    opacity: 0.8;
    transform: translate3d(868px, 300px, 0) rotateX(31deg) rotateZ(15deg)
      scale(1);
  }
  70% {
    opacity: 1;
    transform: translate3d(968px, 280px, 0) rotateX(31deg) rotateZ(330deg)
      scale(1);
  }
  78% {
    opacity: 1;
    transform: translate3d(1329px, 10px, 0) rotateX(46deg) rotateZ(286deg)
      scale(0.8);
  }
  86% {
    opacity: 1;
    transform: translate3d(930px, 20px, 0) rotateX(46deg) rotateZ(286deg)
      scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(0deg) scale(1);
  }
}

@media screen and (max-width: 1023px) {
  .home_content h2 {
    font-size: 52px;
  }
}
@media screen and (max-width: 767px) {
  .home_content h2 {
    font-size: 42px;
    display: flex;
    flex-direction: column;

    span:last-child {
      margin: 0;
    }
  }
}
</style>