<template>
  <div class="irCamera">
    <div class="irCamera_container">
      <div class="irCamera_item video">
        <video :autoplay="true" :loop="true" muted>
          <source src="../image/irCamera/video1.mp4" type="video/mp4" />
        </video>
        <p>目标智能追踪</p>
      </div>
      <div class="irCamera_item ai">
        <img src="../image/irCamera/camera.jpg" />
        <p>AI物种识别</p>
      </div>
      <div class="irCamera_item ir">
        <img src="../image/irCamera/all.png" />
        <p>红外相机</p>
      </div>
    </div>
  </div>
  <!-- <div class="irCamera">
    <div class="irCamera_container">
      <div class="irCamera_video">
        <div class="irCamera_mediaContainer">
          <video :autoplay="true" :loop="true" muted>
            <source src="../image/irCamera/video1.mp4" type="video/mp4" />
          </video>
        </div>
        <p>目标智能追踪</p>
      </div>
      <div class="irCamera_right">
        <div class="irCamera_mediaContainer">
          <img src="../image/irCamera/camera.jpg" />
        </div>
        <p>AI物种识别</p>
      </div>
      <div class="irCamera_main">
        <div class="irCamera_mediaContainer">
          <img src="../image/irCamera/all.png" />
        </div>
        <p>红外相机</p>
      </div>
    </div>
  </div> -->
</template>
<script lang="ts" setup>
</script>
<style lang="scss">
.irCamera {
  width: 100%;
  height: 100%;
  padding: 28px 24px;
  background: #fff;

  &_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 769px) {
    &_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 40% 60%;
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    }
  }
  @media screen and (min-width: 1201px ) {
    &_container {
      width: 1200px;
      margin: 0 auto;
    }
  }

  &_item {
    flex: 1;
    min-height: 0;
    display: block;

    img {
      display: block;
      height: calc(100% - 32px);
    }
  }


  &_item p {
    text-align: center;
    font-family: Source Han Sans CN-Medium,Source Han Sans CN;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #333;
    margin-top: 16px;
  }

  &_item.video video,
  &_item.ai img {
    display: block;
    margin: 0 auto;
    aspect-ratio: 16/9;
    height: calc(100% - 32px);
    box-shadow: 0 4px 20px -7px;
    object-fit: fill;
  }

  @media screen and (min-width: 769px) {
    &_item.video {
      grid-area: 1 / 1 / 2 / 2;
    }
    &_item.ai {
      grid-area: 1 / 2 / 2 / 3;
    }
    &_item.video video,
    &_item.ai img {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }
    &_item.video p,
    &_item.ai p {
      font-size: 24px;
      margin-top: 24px;
    }
  }


  &_item.ir {
    img {
      aspect-ratio: 1/1;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 769px) {
    &_item.ir {
      grid-area: 2 / 1 / 3 / 3;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    &_item.ir p {
      font-size: 36px;
      font-weight: 800;
      margin-left: auto;
    }
    &_item.ir img {
      max-width: 390px;
      max-height: 390px;
      margin-left: 6.25vw;
      margin-right: 0;
    }
  }
  @media screen and (min-width: 1201px) {
    &_item.ir p {
      font-size: 72px;
    }
  }
}
</style>