import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'swiper/swiper.scss'
import 'swiper/modules/pagination/pagination.scss'
import './peimission'
import SwiperCore, {
  EffectFade,Navigation,Pagination
} from 'swiper';
SwiperCore.use([Pagination])
const app = createApp(App)

app.use(router).mount('#app')

